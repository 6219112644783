body {
  margin: 0;
  font-family: "SUIT-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root > div:first-child > div {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 480px;
  --stackflow-plugin-basic-ui-background-color: transparent !important;
}
